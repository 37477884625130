
#selfDispatchTimesheetForm {
    .container-fluid {
        padding: 0;
    }

    .self-dispatch-timesheet-content {
        padding-bottom: 15px;

        &.editable {
            .time-entry-item {
                cursor: pointer;

                > * {
                    cursor: pointer;
                }
            }
        }
    }

    .fa-sticky-note {
    }

    .list-group-item {
        padding: .5rem 1rem;
    }

    .signature-modal-dialog {
        position: fixed;
        top: 0;
    }
}

.time-entry-content {
    min-height: 380px;
}

.react-select__menu-list {
    z-index:12000;
    max-height: 200px;
}

.circular-progress-equipment-loading {
    height: 20px !important;
    width: 20px !important;
}

.is-adjustment {
    background: #f6eddc;
}
