@import "./_variables.scss";

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

.offCanvasMenuButton {
    width: 50px !important;
    height: $navbar-height !important;
    border-radius: 0 !important;

    &:focus {
        box-shadow: none !important;
    }
}

.selectable-hidden-text {
    opacity: 0;
    display: inline-block;
    width: 1px;
}


.offcanvas-active {
    overflow: hidden;
}

.screen-overlay {
    width: 0%;
    height: 100%;
    z-index: 30;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(34, 34, 34, 0.6);
    transition: opacity .2s linear, visibility .1s, width 1s ease-in;

    &.show {
        transition: opacity .5s ease, width 0s;
        opacity: 1;
        width: 100%;
        visibility: visible;
    }
}

.menu-button-wrapper {
    width: 50px;
    height: $navbar-height;
}

.offcanvas {
    width: 320px;
    visibility: hidden;
    transition: all .1s;
    border-radius: 0;
    box-shadow: 0 6.4px 14.4px 0 rgba(0,0,0,.132), 0 1.2px 3.6px 0 rgba(0,0,0,.108);
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1200;
    background-color: $menu-background;
    overflow-y: auto;
    overflow-x: hidden;

    .menu-icon {
        width: 40px;
    }

    .fa, label, li {
        color: $menu-color;
        background-color: $menu-background !important;

        &:hover {
            color: $menu-hover-color;
        }
    }

    .offcanvas-header {
        height: $navbar-height !important;
        background-color: $menu-background !important;
    }

    .offCanvasMenuButton {
        color: $menu-color;

        &:hover {
            color: $menu-color !important;
        }
    }

    &.offcanvas-right {
        right: 0;
        left: auto;
    }

    &.offcanvas-left {
        left: 0;
        right: auto;
    }

    &.show {
        visibility: visible;
    }

    label {
        padding: 0;
        margin: 0;
    }
}

