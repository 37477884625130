@import "../../style/_variables.scss";
div.job-workflow-editor {

    .day-assignments {
        display: flex;
        flex-flow: row wrap;
    }

    .badge {
        font-size: .84em;
    }

    .table-job-workflow-details {
        thead {
            th, th .badge {
                background-color: #ddd;
                font-size: 1em;
            }
        }

        tbody {
            th {
                width: 120px;
                font-size: .85em;
            }

            td, td .badge {
                font-size: .85em;
            }
        }

        th {
            text-transform: unset;
            color: #212529 !important;
        }
    }

    .job-assignment-card {
        box-shadow: 0px 2px 4px 0px #ccc;
        margin-right: 8px;
        border-color: #ccc !important;
    }

    .edit-confirmation-status-toolbar {
        button {
            border-radius: 4px !important;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            .fa {
                margin-left: 6px;
            }

            &.active {
                .fa {
                    color: #fff !important;
                }
            }
        }
    }

    .assignment-status-label {
        &.unfilled {
        }

        &.scheduled {
        }

        &.noresponse {
        }

        &.confirmed {
        }

        &.cancelpending {
            margin-left: 15px !important;
        }

        &.active {
            color: #fff !important;


            &.unfilled {
                background-color: #777 !important;
            }

            &.scheduled {
                background-color: var(--warning) !important;
            }

            &.noresponse {
                background-color: var(--danger) !important;
            }

            &.confirmed {
                background-color: var(--success) !important;
            }

            &.cancelpending {
                font-weight: bold !important;
                background-color: var(--danger) !important;
                margin-left: 15px !important;
            }
        }
    }

    .edit-status-confirmation {
        position: absolute;
        top: 30%;
        left: 0;
        width: 96%;
        margin-left: 2%;
        z-index: 1001;
        display: none;
        background: #fff;
        border: 1px solid #212529 !important;
        box-shadow: 0px 2px 4px 0px #777;

        &.show {
            display: block;
            z-index: 1001;
        }
    }
}