@import "../../style/_variables.scss";

.confirmationContainer {
    background-color: #fff;
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    min-height: 0;
    overflow-y: auto;
}

.job-notes {
    margin-left: 0px !important;
    resize: none;
}

.invoice-lineitem-isadjusted {
    width: 50px;
    text-align: center;

    span {
        background-color: var(--warning);
        color: #fff;
        font-weight: bold;
    }
}

.invoice-lineitem-isheader {
    width: 50px;
    text-align: center;

    span {
        background-color: var(--info);
        color: #fff;
        font-weight: bold;
    }
}

.invoice-lineitem-istext {
    width: 50px;
    text-align: center;

    span {
        background-color: purple;
        color: #fff;
        font-weight: bold;
    }
}

#timesheetForm {

    h4 {
        font-size: 16px;
        border-bottom: 4px solid #ccc;
        margin-bottom: 8px;
        cursor: pointer;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 4px;
    }

    .locationDetails {
        padding: 4px 0;

        *:not(svg):not(.badge):not(.adj-indicator) {
            font-size: 14px;
            line-height: 14px;
            height: auto;
            padding: 1px 0;
        }

        label {
            width: 120px;
            font-size: 14px;
            margin-bottom: 0;
        }

        .form-group {
            margin-bottom: 6px;
        }
    }

    .crewAndEquipmentDetails {
        padding: 4px 0px;

        label {
            font-size: 14px;
            margin-bottom: 0;
        }

        .form-group {
            margin-bottom: 6px;
        }
    }

    .crewTimesheetDetails {
        .not-applicable {
            width: 40px;
            text-align: center;

            .icon-check {
                color: #ccc;

                &.checked {
                    color: var(--danger);
                }
            }
        }

        .start-time,
        .end-time {
            input {
                width: 98px;
                cursor: pointer;
            }

            text-align: center;
        }

        .units-value {
            width: 140px;
        }
    }

    label.bottom-border {
        border-bottom: 1px solid #ccc;
        width: 100%;
        margin-bottom: 4px;
    }

    .assignmentsTable,
    .assignmentsTable {
        width: 100%;
    }

    .assignmentsTable {
        .iconCell {
            width: 40px;
            text-align: center;
        }

        td {
            padding: 4px;
            vertical-align: middle;
        }
    }

    #officeCallButton {
        background: #28a745;
        color: #fff;
        margin-right: 8px;
    }

    .icon-check {
        color: #ccc;

        &.checked {
            color: #28a745
        }
    }

    .workOrderGroup {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 4px 8px;
        background: whitesmoke;
        margin-bottom: 4px;

        label {
            min-width: 80px;
            font-size: 12px;
            margin-bottom: 0;
        }
    }

    a {
        cursor: pointer;
    }

    .userActions {
        border-top: 1px solid #ccc;
        padding-top: 8px;
    }

    .timesheetHeader {
        font-weight: bold;
        font-size: 12px;
    }

    .invalid {
        input {
            border: 1px solid var(--danger);
        }
    }

    input.invalid {
        border: 1px solid var(--danger);
    }

    .control-label {
        margin-bottom: 4px;
        margin-left: 2px;
    }

    .timesheet-complete {
        color: limegreen;
        font-weight: bold;
        text-decoration: none;
    }

    .detailscharge {
        overflow: visible;
    }

    .detailsname {
        overflow: visible;
    }

    .detailsstart {
        width: 80px;
        text-align: right;
    }

    .detailsend {
        width: 80px;
        text-align: right;
    }

    .detailsqty {
        width: 80px;
        text-align: center;
    }

    .detailsunits {
        width: 80px;
        text-align: center;
    }

    .detailsbillable {
        width: 80px;
        text-align: center;
    }

    .detailsbreak {
        width: 80px;
        text-align: center;
    }

    .isadjusted {
        width: 50px;
        text-align: center;

        span {
            background-color: var(--warning);
            color: #fff;
            font-weight: bold;
        }
    }

    .detailsdelete {
        width: 40px;
        text-align: center;
    }

    .detailsadjtype {
        width: 180px;
    }

    .adjustment-table,
    .timesheet-details-table {
        /*table-layout: fixed !important;*/
        //this is causing scrunch on mobile

        .invalid-feedback {
            font-size: 11px !important;
            margin-left: 4px;
        }

        //Hide any validation images, since this adjustment row is cramped.
        .was-validated .form-control:valid,
        .form-control {
            background-image: none !important;
            padding-right: 0.5rem !important;
        }
    }

    .timesheet-details-table {
        tr.strikeout {
            position: relative;

            td {
                position: relative;

                &:not(:first-child):not(:last-child) {
                    opacity: .6;

                    &:before {
                        content: " ";
                        position: absolute;
                        top: 50%;
                        left: 0;
                        border-bottom: 1px solid #111;
                        width: 100%;
                    }
                }
            }
        }

        .details-remove-button {
            opacity: .5;

            &.removed {
                opacity: 1;
            }
        }
    }

    .timesheet-details-table-row.adjusted {
        background-color: lemonchiffon;
    }

    .timesheet-details-signature-button {
        width: 40px;
        text-align: center;
    }

    .timesheet-details-signature-image {
        width: 250px;
        text-align: center;
    }

    .userNote {
        border-radius: 4px;
        padding: 4px;
        background-color: lightblue;
        display: flex;
        flex-flow: column nowrap;

        .noteContent {
            font-size: 11px;
        }

        .noteContentByLine {
            font-size: 11px;
            font-weight: bold;
            color: blue;
            text-align: right;
        }
    }
}

#timesheetsTable {
    tbody {
        tr {
            &.timesheet-row {
                & .site-link {
                    white-space: initial;
                }

                &.timesheet-deleted {
                    opacity: 0.95;
                    pointer-events: none;
                    /* Disable interactions */
                    transition: all 0.3s ease-in-out;

                    .site-link {
                        color: var(--gray-dark);
                    }
                }
            }
        }
    }
}