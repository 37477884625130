.collapse-header {
    font-size: 16px !important;
    font-weight: 500 !important;
    border-bottom: 4px solid #ccc !important;
    margin-bottom: 8px !important;
    display:flex !important;
    flex-flow:row nowrap !important;
    align-items:center !important;
    justify-content:space-between !important;
    padding-bottom:4px !important;
}
