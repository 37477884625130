@import "../../style/_variables.scss";

$drop_min_height: 50px;

.dispatch-board {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    min-height: 0;

    .form-group {
        margin-bottom: 0;
    }

    label {
        font-size: .6rem;
        color: #777;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: .1rem;
    }
}

#crewsPane, #dispatchLeft, #dispatchRight {
    flex: 1 1 auto;
    display: flex;
    opacity: 1;
    flex-flow: column;
    padding: 0;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #ccc;
    @include bottomRightOuterBoxShadow;

    legend {
        padding: 4px 10px;
        font-size: .85rem;
        font-weight: 900;
        color: #212529;
        background: transparent;
        text-transform: uppercase;
        border-bottom: 4px solid $default_border;
        margin-bottom:0;
    }
}

.max100 {
    max-height: 100%;
}

.max80 {
    max-height: 85%;
}

#dispatchRight + .gutter {
    display:none;
}

#dispatchBottom {
    flex: 1 1 auto;
    display: flex;
    opacity: 1;
    flex-flow: column;
    padding: 0;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #ccc;
    @include bottomRightOuterBoxShadow;
    height:12%;

    legend {
        padding: 4px 10px;
        font-size: .85rem;
        font-weight: 900;
        color: #212529;
        background: transparent;
        text-transform: uppercase;
        border-bottom: 4px solid $default_border;
        margin-bottom: 0;
    }
}

.dispatchPane {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 100%;
    height: 100%;
    max-width: 100%;
}

#dispatchLeft {
    flex:2;
    max-width: 1150px;
}

#crewsPane {
    position:relative;
    display: none;
    flex:1;
    margin-right:10px;
}

#crewsClose {
    position:absolute;
    right:10px;
    top:6px;
    cursor:pointer;
}

#dispatchWrapper {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row wrap;
    min-height: 0;

    &.crews-open {
        #dispatchRight {
            display: none !important;
        }

        #crewsPane {
            display: flex;
        }
    }
}

#dispatchCenterContentHeader {
    padding: 0 10px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;

    > div {
        padding-top:6px;
    }
}

#dispatchCenterToolbar {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    padding: 10px 10px 10px 10px;
}

#dispatchCenterToolbarRight {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-content: center;

    input[type=search] {
        max-width: 200px;
    }
}

.nav-tabs {
    border-bottom: none;

    .nav-link {
        padding: 0.25rem .5rem;
        font-size: .85rem;
        font-weight: 900;
        color: #777;
        text-transform: uppercase;
        opacity: .8;
        border-width: 0 0 4px 0;
        cursor: pointer;

        &.active {
            opacity: 1;
            color: #212529;
            border-bottom: 4px solid var(--defaultHighlight);
        }
    }
}

#crewButton {
    color: var(--defaultHighlight);

    &:hover {
        cursor: pointer;
        color: #212529
    }
}

#dispatchCenterContent {
    margin: 0 10px 4px 10px;
    flex: 1 !important;
    padding: 10px;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    border-style: solid;
    border-color: $default_border;
    border-width: 2px;
    @include topLeftInnerBoxShadow;
    position:relative;
}

.staffCard, .equipmentCard {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid $default_border;
    @include bottomRightOuterBoxShadow;

    .card-title {
        border-bottom: 2px solid $default_border;
        margin-bottom: 4px;
        font-size: .8rem;
    }

    .card-body {
        padding: 6px 6px 6px 3px;
    }

    .card-footer {
        margin: 0;
        padding: 0;
        border: none;

        .btn.active {
            background: var(--success);
            color: #fff;
        }

        .btn.cancelled {
            background: #FF82A7;
            color: #fff;
        }
    }

    .dragIndicator {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 3px;
        color: #777;
        font-size: 10px;
    }

    .scheduledDayIndicator {
        padding: 0 0.25rem;
        cursor: default;
        color: #777;
        pointer-events: none;

        &:hover {
        }
    }


    &.compact {
        margin: 0;
        padding: 4px 8px;
        box-shadow: none;
        width: 100%;
        min-height: $drop_min_height;

        .card-title {
            border-bottom: none;
            margin-bottom: 0;
            font-size: .8rem;
        }

        .card-body {
            padding: 0;
            margin: auto;
        }
    }

    .staff-name {
        font-size: 11px;
        text-overflow: ellipsis;
    }

    &.minimal {
        height: 48px !important;
        margin: 0 !important;

        .card-title {
        }

        .card-footer {
            display: none !important;
        }
    }
}

.dragging {
    .staffCard, .equipmentCard {
        opacity: .6;
        margin-right: 0px;
        margin-bottom: 0px;
    }
}

#dispatchCenterFooter {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0 10px 10px 10px;
}

#weekRange {
    margin-right: 10px;

    .react-select {
        width: 220px;
        max-width: 220px;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;

        > * {
            border-radius: 0 !important;
        }
    }
}

#dispatchWeekdaySelection {
    .btn.active {
        background: var(--success);
        color: #fff;
    }
}

.dispatch-location {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;

    .react-select {
        width: 180px;
        max-width: 180px;
    }
}

.flex-spacing {
    flex: 1 1 auto;
}

#dispatchRightContentHeader {
    margin: 0 10px;
    padding-top: 6px;
    display: flex;
    flex-flow: row nowrap;
}

#dispatchRightContentHeaderLeft {
    flex:1;
}

#notificationFormGroup {
    max-width: 120px; 
}

#dispatchRightContent {
    flex: 1 1 auto;
    margin: 0 10px;
    display: flex;
    flex-flow: column;
    min-height:0;

    .planning-content {
        flex:1;
        display:flex;
        flex-direction: column;
        min-height:0;
    }

    .ops-content {
    }

    thead {
        tr {
            border-bottom: 2px solid $default_border;
        }
        th {
            color: var(--primary);
        }
    }
}

.scoreboard {
    margin: 8px 0 0 0;

    tr > th:not(:first-child),
    tr > td:not(:first-child) {
        width: 100px !important;
    }

    tbody {
        td {
            font-size: 11px;
        }
    }
}

#dispatchJobTabs {
    border-bottom: 1px solid $default_border;
}

#jobSearchText {
    max-width: 200px;
}

.job-listing-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    min-height: 0;

    .job-listing-container-header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 8px;

        .job-listing-container-subheader {
            font-weight: bold;
        }

        .job-listing-filters {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }
    }

    .job-listing {
        flex: 1 1 auto;
        overflow-y: auto;
        min-height: 0;
        padding: 6px;
        @include topLeftInnerBoxShadow;
        border: 1px solid $default_border;

        .jobListItem {
            padding: 4px 8px;
            font-size: .8rem;
            border-width: 4px;
            margin-bottom: 4px;
            border: 1px solid $default_border;
            border-radius: 4px;
            @include bottomRightOuterBoxShadow;

            * {
                font-size: 11px;
            }

            &.details-open {
            }
        }
    }

    .job-details {
        border-top: 2px solid #ccc;

        .job-details-header {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            font-size: .8rem;
            padding: 4px 0px;

            .badge {
                min-width: 19px;
            }
        }

        table {
            th, td {
                text-align: center;
            }

            th {
                font-size: 11px;
                font-weight: 600;
                padding-bottom: 0;

                .job-assignment-table-header {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: center;

                    .badge {
                        margin-left: 6px;
                        font-size: 11px;

                        &.active {
                            color: var(--primary);
                        }
                    }
                }
            }
        }
    }

    .jobAssignmentsTable {
        width: 100%;
        table-layout: fixed;

        tbody {
            td {
            }
        }
    }

    .assignmentsWrapper {
        display: flex;
        flex-flow: row nowrap;

        .assignmentsToolbarCell {
            width: 46px;

            .resourceType {
                cursor: pointer;

                &.selected {
                    background: $border_light;
                    margin-right: -1px;
                }
            }
        }

        .assignments {
            display: flex;
            flex-flow: column nowrap;
            flex: 1 1 auto;
            padding: 0;


            .jobAssignmentDropTarget {
                margin: 4px 4px;
                background: $border_light;
                flex: 1;
                position: relative;
                z-index: 1;
                min-height: $drop_min_height;
                max-height: $drop_min_height;
                height: $drop_min_height;


                .dropTargetIcon {
                    color: #ccc;
                }

                &.show {
                    border-width: 2px;
                    border-style: dashed;
                    border-radius: 4px;
                    border-color: #ccc;
                    padding: 4px;
                }

                &.dragging {
                    border-color: var(--success);

                    .dropTargetIcon {
                        color: var(--success);
                    }
                }

                &.invalidDropZone {
                    background-color: rgba(222, 226, 230, 0.3);
                }
            }

            .jobEquipmentAssignment {
                display: flex;
                flex-flow: row nowrap;
                padding-left: 40px;
                align-items: center;
                position: relative;
                padding-top: 4px;

                &::before {
                    content: '';
                    @include fontAwesomePseudo;
                    height: 8px;
                    width: 6px;
                    position: absolute;
                    top: -2px;
                    right: 50%;
                    margin-right: -3px;
                    background: #ccc;
                    z-index: 1;
                }
            }
        }
    }

    .jobAssignment {
        margin-bottom: 6px;
    }

    .assignmentDropdown {
        padding-right: 8px;
        display: flex;
        flex-flow: column nowrap;

        select {
            cursor: pointer;
            border: none;
            height: calc(1em + 2px);
            line-height: 1;
            max-width: 200px;
            padding: 0px;
            margin: 0;
            text-align-last: right;

            option {
                cursor: pointer;
            }

            &:hover, &:focus {
                box-shadow: none !important;
            }
        }
    }

    .jobListItemHeader {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .job-name {
            font-weight: 600;
        }

        > * {
            font-size: 12px !important;
        }
    }
}

label.dropPlaceholder {
    font-size: 11px !important;
    font-weight: 600 !important;
    text-transform: none !important;
}

.dropPlaceholderWrapper {

    position:absolute;
    top:30%;
    left:0;
    width:100%;
}

#dispatchRightContentFooter {
    display: flex;
    flex: 0;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-end;
    margin: 4px 10px 10px 10px;
}

#main, .dispatch-board, #dispatchWrapper, #dispatchPane, #dispatchLeft, #dispatchCenterContent {
    transform: none !important;
}

.hidden {
    display: none !important;
}

#staffSortButton {
    height:27px;
    line-height:27px;
    font-size:11px;
}

.crewLeadBadge {
    color: #777;

    &.isCrewLead {
        color: #ff8d00;
    }
}

.assignment-status-label {

    &.unfilled {
        color: #777 !important;
    }

    &.scheduled {
        color: var(--warning) !important;
    }

    &.noresponse {
        color: var(--danger) !important;
    }

    &.confirmed {
        color: var(--success) !important;
    }

    &.cancelpending {
        font-weight: bold !important;
        color: var(--danger) !important;
    }
}

.saving-progress {
    display: none;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background: rgba(255, 255, 255, .5);
    color: #000;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.saving {
    position: relative;
    pointer-events: none !important;

    * {
        pointer-events: none !important;
    }

    .saving-progress {
        display: flex;

        .fa-circle-notch {
            font-size: 48px !important;
        }
    }
}

#notificationModal {
    button.close {
        display:none !important;
    }
}

.preferred-dispatch-icon {    
    &.active {
        color: goldenrod;
    }
}

.assignment-placeholder-row {
   display:flex;
   flex-fill: row nowrap;
   align-items:center;
}

.jobCancellationBadge {
    border: 1px solid #bdbcbf;
    border-radius: 4px;
    display: flex;
    flex-flow: column nowrap;
    padding:6px;
    margin-bottom: 8px;

    .jobCancellationBadgeDetails {

        .jobCancellationBadgeAsn {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        .jobCancellationBadgeAsnCancelType {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
        }
    }

    .cancelConfirmAlert {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 0 6px;
        margin:0;
    }

    > div {
        margin-right: 6px;
    }
}

.dailyAttendanceEventsContainer {
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    height: 70%;

    .card {
        flex: 1 1;
    }
}

.dailyAttendanceEventCard {
    div {
        height: 100%;
        position: relative;

        .card-title {
            margin-bottom: 0.75rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            transition: all .2s linear;
        }

        .card-title:focus, .card-title:hover {
            color: transparent;
        }

        .card-title:focus:after, .card-title:hover:after {
            content: attr(data-text);
            overflow: visible;
            text-overflow: inherit;
            background: #fff;
            position: absolute;
            border: 1px solid #eaebec;
            padding: 0 0.5rem;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
            white-space: normal;
            word-wrap: break-word;
            display: block;
            color: black;
            margin-top: -1.5rem;
            z-index: 100;
        }

        .card-body {
            padding: 0.5em;

            .title-separator {
                display: inline-block;
                width: 96%;
                position: absolute;
                border-bottom: 2px solid #bdbcbf;
                left: 2%;
                bottom: 25px;
            }

            .badge {
                position: absolute;
                bottom: 3px;
            }

            .remove-icon {
                position: absolute;
                bottom: 5px;
            }
        }
    }
}

.badge-bigger {
    font-size: 0.8rem;
}

@include desktopMdDown {
    #dispatchCenterToolbar {
        flex-flow: column;
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    #dispatchResourceTabs {
        justify-content: center;
        border-bottom: 1px solid #ccc;
    }

    #dispatchCenterToolbarRight {
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@include tabletDown {

    #dispatchLeft {
        flex: 1 1 auto;
    }

    #dispatchCenterContentHeader {
        > div {
            padding-bottom: 10px;
        }
    }

    #dispatchRight {
        flex: 1 1 auto;
    }

    #dispatchWrapper {
        flex-flow: column;
    }
}

@include phone {

    .dispatch-board {
        overflow-y: auto;
    }

    .tabName {
        display: none;
    }

    #dispatchPane {
        flex-direction: column;
    }

    #dispatchCenterContentHeader {
        flex-flow: column;
        align-content: center;
        justify-content: center;
    }

    #dispatchResourceTabs {
        .fa {
            display:none;
        }
    }

    #dispatchCenterToolbarRight {
        .btn-group {
            margin-bottom: 10px;
        }
    }
}
