#myTimesheetsContent {
    padding-top: 4px !important;

    [role="gridcell"] {
        display: flex;
        align-items: center;
    }

    .ag-header-row-floating-filter {
        display: none !important;
    }

    #myTimesheetsMonthSelector {
        max-width: 150px;
    }

    .my-timesheet-row-content {
        padding: 11px 0 !important;

        &:hover {
            text-decoration: none;
        }

        > * {
            line-height: 1em;
        }
    }

    .timesheet-date {
        line-height: 1.25em;
        padding: 4px 0 2px 0;
        font-weight: bold;
    }

    .timesheet-number {
        line-height: 1em;
        font-weight: bold;
    }

    .timesheet-status {
        font-weight: bold;
        text-transform: uppercase;
    }

    .timesheet-status-rejected,
    .timesheet-status-billingpayrollrejected {
        background: hsla(360, 42%, 44%, 0.22);
    }

    .ag-icon-filter {
        display: none;
    }

    .text-secondary {
        color: #6c757d !important;
    }
}
