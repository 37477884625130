﻿//fonts
$font-fa: "Font Awesome 5 Free";

//viewport definitions
$w-phone-sm: 320px;
$w-phone-lg: 411px;
$w-tablet: 768px;
$w-desktop: 1024px;
$w-desktop-md: 1200px;
$w-desktop-lg: 1366px;

@function unicode($str) {
    @return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"");
}

@mixin fontAwesomePseudo {
    font-family: $font-fa;
    font-style: normal;
}

@mixin baseDotNotation {
    @include fontAwesomePseudo;
    font-weight: 900; //filled circle
    vertical-align: middle;
}

@mixin dotNotation($class, $color, $size, $fa-icon-unicode) {
    #{$class} {


        &#{$class}-before::before, &#{$class}-after::after {
            content: unicode($fa-icon-unicode);
            color: $color;
            font-size: $size;
            @include baseDotNotation;
        }

        &#{$class}-before::before {
            margin-right: 6px;
        }

        &#{$class}-after::after {
            margin-left: 6px;
        }
    }
}

@mixin topGradientBorder {
    border-style: solid !important;
    border-color: rgb(128, 128, 128);
    border-width: 1px 0 0 0;
    border-image: linear-gradient(270deg, rgba(255, 255, 255, 1), rgba(128, 128, 128, 1.0)) 1;
}

@mixin bottomRightOuterBoxShadow {
    -webkit-box-shadow: 2px 2px 6px -1px rgba(0,0,0,0.3);
    box-shadow: 2px 2px 6px -1px rgba(0,0,0,0.3);
}

@mixin topLeftInnerBoxShadow {
    -webkit-box-shadow: inset -2px 2px 10px -1px rgba(0,0,0,0.4);
    box-shadow: inset -2px 2px 10px -1px rgba(0,0,0,0.4);
}

/* Viewports */
@mixin phone {
    @media (min-width: #{$w-phone-sm}) and (max-width: #{$w-tablet - 1px}) {
        @content;
    }
}

@mixin phoneDown {
    @media (max-width: #{$w-tablet - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$w-tablet}) and (max-width: #{$w-desktop - 1px}) {
        @content;
    }
}

@mixin tabletDown {
    @media (max-width: #{$w-desktop - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$w-desktop}) {
        @content;
    }
}

@mixin desktopMdDown {
    @media (max-width: #{$w-desktop-md}) {
        @content;
    }
}

@mixin redTheme() {
    .red-theme {
        @content;
    }
}

@mixin orangeTheme() {
    .orange-theme {
        @content;
    }
}