@import "./_variables.scss";

.datatable {
    height: 100%;

    .customHeaderMenuButton {
        float: left;
        margin: 0 0 0 3px;
    }

    .customHeaderLabel {
        float: left;
        margin: 0 0 0 3px;
    }

    .customSortDownLabel {
        float: left;
        margin: 0 0 0 3px;
    }

    .customSortUpLabel {
        float: left;
        margin: 0;
    }

    .customSortRemoveLabel {
        float: left;
        margin: 0 0 0 3px;
        font-size: 11px;
    }

    .active {
        color: var(--defaultHighlight);
    }

    .ag-header-cell {
        .ag-icon {
            cursor: pointer;
        }
    }
}

.ag-overlay {
    z-index: 1 !important;
}

.ag-header-cell-resize {
    z-index: 1 !important;
}

.ag-theme-alpine {
    font-family: 'Roboto', sans-serif !important;
    font-size: 100% !important;

    .ag-row {
        font-size: 100% !important;

    }
}

.ag-body-viewport {
    overflow-y: scroll !important;
}

/* AGGrid fixes */
.ag-root-wrapper {
    overflow: visible !important;
    /* RLC: Allows filter popups to bleed past small grid bottoms. */
}

.ag-popup {
    z-index: 2 !important;
}

.ag-popup-child {
    height: auto !important;
    overflow: visible !important;
}

/* Datagrid default styles */
div.ag-theme-alpine {

    div.ag-cell {
        line-height: 34px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.ag-row-danger {
    background-color: rgba(244, 67, 54, 0.5) !important;
}

.ag-row-warning {
    background-color: rgba(255, 152, 0, 0.5) !important;
}

.ag-row-success {
    background-color: rgba(76, 175, 80, 0.5) !important;
}

.ag-row-info {
    background-color: rgba(33, 150, 243, 0.5) !important;
}

.ag-row-otjobstart {
    background-color: rgba(204, 204, 255, 0.5) !important;
}

.ag-row-nopsschedule {
    background-color: rgba(244, 67, 54, 0.5) !important;
}

.ag-row-regoutoforder {
    background-color: rgba(242, 190, 188, 0.5) !important;
}

.ag-row-otoutoforder {
    background-color: rgba(244, 67, 54, 0.5) !important;
}

.ag-cell-danger {
    background-color: rgba(244, 67, 54, 0.5) !important;
}

.ag-cell-warning {
    background-color: rgba(255, 152, 0, 0.5) !important;
}

.ag-cell-success {
    background-color: rgba(76, 175, 80, 0.5) !important;
}

.ag-cell-info {
    background-color: rgba(33, 150, 243, 0.5) !important;
}

.datagrid-toolbar-row {
    @include phoneDown {
        .datagrid-toolbar-button-row {
            justify-content: center;
            width: 100%;
            flex: 1;
            margin: 0;

            button {
                font-size: 1.2em !important;
            }

            div.datagrid-toolbar-button-row-left {}

            div.datagrid-toolbar-button-row-right {}
        }

        .small-viewport-hide {
            display: none;
            margin: 0 !important;
        }
    }
}

.datagrid-toolbar-mobile-title {
    display: none;

    @include phoneDown {
        display: block;
    }

}


.flag-tooltip {
    width: 150px;
    height: 70px;
    border: 2px solid;
    border-radius: 5px;
    overflow: hidden;
}

.flag-tooltip p {
    margin: 5px;
    white-space: nowrap;
}

.ag-header {
    min-height: 78px !important;
}

.ag-header-row-column-filter {
    height: 47px !important;
}