@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/mixins';

// override bootstrap functions to comply with --vars
@import '_functions-override.scss';

@import './_variables.scss';

// Import Bootstrap source files
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/code';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/transitions';
@import '../../node_modules/bootstrap/scss/dropdown';
@import '../../node_modules/bootstrap/scss/button-group';
@import '../../node_modules/bootstrap/scss/input-group';
@import '../../node_modules/bootstrap/scss/custom-forms';
@import '../../node_modules/bootstrap/scss/nav';
@import '../../node_modules/bootstrap/scss/navbar';
@import '../../node_modules/bootstrap/scss/card';
@import '../../node_modules/bootstrap/scss/breadcrumb';
@import '../../node_modules/bootstrap/scss/pagination';
@import '../../node_modules/bootstrap/scss/badge';
@import '../../node_modules/bootstrap/scss/jumbotron';
@import '../../node_modules/bootstrap/scss/alert';
@import '../../node_modules/bootstrap/scss/progress';
@import '../../node_modules/bootstrap/scss/media';
@import '../../node_modules/bootstrap/scss/list-group';
@import '../../node_modules/bootstrap/scss/close';
@import '../../node_modules/bootstrap/scss/toasts';
@import '../../node_modules/bootstrap/scss/modal';
@import '../../node_modules/bootstrap/scss/tooltip';
@import '../../node_modules/bootstrap/scss/popover';
@import '../../node_modules/bootstrap/scss/carousel';
@import '../../node_modules/bootstrap/scss/spinners';
@import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/print';

html,
body,
#root {
    height: 100%;
    min-height: 100%;
    overflow: hidden;
}

body {
    display: flex;
    flex-flow: column;
    font-family: 'Roboto', sans-serif !important;
}

#root {
    flex: 1;
    display: flex;
    flex-flow: column;
}

#main {
    flex: 1;
    display: flex;
    flex-flow: column;
    min-height: 0;
    background-color: #eeeeee;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.site-page-wrap {
    background: #fff;
}

#main,
.site-page-wrap {
    overflow: hidden;
}

.environment-tag {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;
    margin: 0;
    z-index: 1;
    border-radius: 0;
}

.background-white {
    background: #fff;
}

.page-hidden {
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
    -webkit-transition: opacity 0.8s;

    &.ready {
        opacity: 1 !important;
    }
}

.btn-link {
    background: none;
}

#siteHeader {
    z-index: 2;

    nav {
        height: 40px;
        border-bottom: 1px solid #ccc !important;
    }
}

.site-link {
    font-weight: bold;
    text-decoration: none;
    color: var(--defaultHighlight);
    cursor: pointer;

    &:hover {
        color: var(--success);
    }
}

button.site-link {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    padding: 0;
    text-align: left;
}

.nav-icon {
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: var(--success);
    }
}

.navbar-brand {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    img {
        max-height: 32px;
        max-width: 120px;
    }

    .brand-label {
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        height: 32px;
        line-height: 32px;
        margin: 0;
        margin-left: 0.4rem;
    }
}

.login-message {
    margin-top: 40px;
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 40px;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: 0 8px 6px -6px #777;
}

.nav-menu-notification-badge {
    font-size: 14px;
    padding: 2px 8px 2px 6px !important;
    text-align: center;
}

//RLC: Wrapping text elements with this will provide a clear button.
//
// Usage:
// <div class="text-clear-enabled-wrapper" >
// <input type="text" class="form-control" placeholder="Search" / >
// </div >
//
.text-clear-enabled-wrapper:hover {
    padding: 0;
    position: relative;

    >input {
        padding-right: 24px;
    }

    &::after {
        position: absolute;
        font-family: 'Font Awesome\ 5 Free';
        font-weight: normal;
        content: '\f056';
        right: 4px;
        top: 50%;
        margin-top: -11px;
        color: #ddd;
        cursor: pointer;
        height: 16px;
        width: 16px;
    }
}

.footer {
    .container {
        text-align: center;
    }
}

.site-button-small {
    font-size: 12px;
    margin-left: 4px;
    margin-right: 4px;

    &.floating {
        position: absolute;
        top: 0;
        right: 0;
    }
}

ul.pagination {
    li .paginate_button {
        margin: 1px;

        &.page-item {
            &.active {
                a .page-link {
                    background-color: var(--defaultHighlight);
                    color: #000;
                }
            }

            a.page-link {
                background-color: #ddd;
                color: #000;
            }
        }
    }
}

.resource-weekday-col {
    width: 60px;
}

/* RLC: Provides a marker for a required field.  
        Usage: <label class="required required-after">Username</label>*/

@include dotNotation('.required', red, 0.4em, 'f111');

/* RLC: Provides a marker for an unordered list styled field.  
        Usage: <label class="unordered unordered-before">Material Name</label>*/

@include dotNotation('.unordered', #777, 1em, 'f0da');

.table-responsive {
    position: relative;
}

.table-sm {
    th {
        font-size: 0.7em;
        text-transform: uppercase;
        color: #777 !important;

        button {
            font-size: inherit;
            text-transform: inherit;
        }
    }
}

input.with-clear {
    padding-right: 20px;
}

.clear-button {
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    height: 14px;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    color: #ccc;
}

/* end Site Framework */

/* Bootstrap Assistants */
.btn:focus,
button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.btn-secondary {
    color: #000;
}

.btn-success,
.badge-success {
    background-color: var(--success);
}

//Fix vertical alignment issues in dropdown buttons.
button.dropdown-toggle {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.control-label {
    color: #777;
    font-weight: 600;
    font-size: 0.9em;
}

.form-control-plaintext {
    border: none !important;
    outline: none !important;
    margin: 0;
    padding: 0;
    margin-left: 16px;
    font-size: 14px;
    color: #777;
}

.form-group {
    display: flex;
    flex-flow: column nowrap;

    &.relative {
        position: relative;
    }
}

//Control bootstrap input groups and force block style//bound box
.box,
.box .selectize-input {
    border-radius: 0 !important;
}

.right-box,
.right-box .selectize-input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.left-box,
.left-box .selectize-input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.side-by-side {

    //RLC: the below allows us to blanket apply red dot notation
    //by selecting any inputs that are required, then adding the
    //required styles.
    .form-group {
        flex-flow: column nowrap;
    }
}

.pointer-events-none {
    pointer-events: none !important;
    cursor: default;
}

/* RLC: Make react-select play nice with BS input groups */
.input-group {
    .input-group-prepend~.react-select {
        flex: 1;

        >div {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }
}

//RLC: Don't show remove buttons when the react select dropdown is disabled.
.react-select__multi-value--is-disabled {
    .react-select__multi-value__remove {
        display: none !important;
    }
}

.smallReactSelect {
    .react-select__control {
        height: 28px;
        min-width: 380px;
    }
}

.smallReactAsync {
    .react-async__control {
        height: 35px;
        min-height: 0;
    }

    .react-async__value-container {
        width: 341px;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .react-async__indicators {
        padding-top: 0px;
        padding-bottom: 0px;
        height: 28px;
    }

    .react-async__indicator {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

/* RLC: Filter container in floating filters does not expand to parent */
/*.ag-react-container {
    width:100%;
}

.ag-react-container {
    width: 100%;
}

.ag-cell {
    overflow: visible;
}

.ag-row {
    z-index: 0;
}

.ag-root,
.ag-root-wrapper,
.ag-body-viewport,
.ag-body-viewport-wrapper,
.ag-center-cols-clipper,
.ag-center-cols-viewport,
.ag-header,
.ag-header-viewport,
.ag-header-container, 
.ag-header-row, 
.ag-header-cell, 
.ag-floating-filter-body, 
.ag-floating-filter-full-body, 
.ag-react-container {
    overflow:visible !important;
}*/
.btn-group {
    .btn-secondary {
        &.active {
            color: #fff !important;
            background-color: #1d2124CC !important;
            border-color: #171a1d !important;
        }
    }

    .btn-outline-secondary {
        color: #1d2124CC;
    }

    &.invalid {
        border: 1px solid var(--danger);
        border-radius: 4px;
    }
}

//RLC: If buttons are appended to another control,
//eliminate rounded left edges.
.input-group-append {
    .btn-group {
        .btn {
            border: 1px solid #ced4da;

            &:first-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &.active {
                background: var(--defaultHighlight);
                border-color: var(--defaultHighlight);
                color: #000;
            }
        }
    }
}

.input-group-text {
    &.primary {
        background-color: #000 !important;
        color: #fff;
        border: 1px solid #000;
    }

    &.secondary {
        background-color: var(--defaultHighlight) !important;
        color: #000;
        border: 1px solid var(--defaultHighlight);
    }
}

.popover-header {
    background: #ddd;
}

.plw-card {
    border-width: 4px;

    &:hover {
        border-color: #777;
        background: #fff4c9;

        .card-subtitle {
            color: #000 !important;
        }
    }
}

//Checkboxes
.chk {
    position: relative;
    width: 18px;
    height: 18px;
    border: 1px solid #c8ccd4;
    border-radius: 3px;
    vertical-align: middle;
    transition: background 0.1s ease;
    cursor: pointer;
    margin-bottom: 0 !important;
    margin-right: 6px;
    background: #fff;
}

.chk:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 6px;
    width: 6px;
    height: 12px;
    opacity: 0;
    transform: rotate(45deg) scale(0);
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transition: all 0.3s ease;
    transition-delay: 0.15s;
}

.chk-l {
    margin-left: 5px;
    vertical-align: middle;
    cursor: pointer;
}

.chk-i:checked~.chk {
    border-color: transparent;
    background: var(--defaultHighlight);
    animation: jelly 0.6s ease;
}

.chk-i:checked~.chk:after {
    opacity: 1;
    transform: rotate(45deg) scale(1);
}

.chk-i[disabled]~span {
    opacity: 0.5;
}

.read-only-check-wrap * {
    pointer-events: none !important;
}

div.form-check {
    small {
        line-height: 0.875em;

        &>span,
        &>svg {
            vertical-align: middle;
        }
    }

    &>div {
        position: relative;

        input[type="checkbox"] {
            position: absolute;
            z-index: -1;
            opacity: 0;

            &+label {
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                user-select: none;
                width: auto;

                // Before = checkbox
                &::before {
                    content: '';
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    border: 1px solid #c8ccd4;
                    background: var(--white);
                    border-radius: 3px;
                    margin-right: 6px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 50% 50%;
                    transition: background 0.1s ease;
                }

                // After = checkmark
                &::after {
                    content: '';
                    position: absolute;
                    left: 6px;
                    width: 6px;
                    height: 12px;
                    opacity: 0;
                    transform: rotate(45deg) scale(0);
                    border-right: 2px solid #fff;
                    border-bottom: 2px solid #fff;
                    transition: all 0.3s ease;
                    transition-delay: 0.15s;
                }
            }

            &:checked+label {
                &::before {
                    border-color: transparent;
                    background: var(--defaultHighlight);
                    animation: jelly 0.6s ease;
                }

                &::after {
                    opacity: 1;
                    transform: rotate(45deg) scale(1);
                }
            }

            &:focus+label {
                &::before {
                    box-shadow: 0 0 0 0.2rem hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.25)
                }
            }

            &:disabled+label {
                cursor: not-allowed;

                &::before {
                    background: $input-disabled-bg; // Greyed-out background
                    border-color: $input-border-color; // Greyed-out border
                }

                &::after {
                    border-color: $input-border-color; // Optional for checkmark color when disabled
                }

                span,
                small {
                    color: $gray-600; // Greyed-out text
                }
            }

            // Checked and disabled
            &:checked:disabled+label {
                &::before {
                    background: darken($input-disabled-bg, 10%); // Make background slightly darker for contrast
                }

                &::after {
                    border-color: $gray-600; // Darker color for checkmark
                    border-right-width: 3px; // Slightly thicker checkmark
                    border-bottom-width: 3px; // Slightly thicker checkmark
                }
            }
        }
    }
}

//end Checkboxes

// Centering fix
.cntr {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
}

.cursor-pointer {
    cursor: pointer;
}

//Font Awesome Assistants
.fas-subscript-wrapper {
    position: relative;

    .fas-subscript {
        position: absolute;
        border-radius: 12px;
        overflow: hidden;
        background: #fff;
        bottom: -2px;
        right: -6px;
        z-index: 1;
        color: var(--defaultHighlight);
    }
}

.fas[data-count] {
    position: relative;
}

.fas[data-count]:after {
    position: absolute;
    right: -2.5em;
    top: -0.3em;
    content: attr(data-count);
    padding: 0.5em;
    border-radius: 5em;
    line-height: 0.9em;
    color: white;
    background: rgba(255, 0, 0, 0.75);
    text-align: center;
    min-width: 2em;
    font: bold 0.25em sans-serif;
}

//end Font Awesome

//Maps - background container image and help text.
#emp_routing_map {
    position: relative;
    z-index: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border-width: 4px !important;
    border-radius: 4px;

    &::before,
    &::after {
        opacity: 0.8;
        color: #777;
        z-index: -1;
    }

    &::before {
        font-family: $font-fa;
        font-weight: 900;
        content: '\f276';
        font-size: 96px;
    }

    &::after {
        text-align: center;
        content: 'Click [Find on Map] to display the routing address location.';
    }
}

//Disable all form elements in a fieldset
fieldset {
    opacity: 0;
    transition: opacity ease-in-out 0.2s;
    background: #fff;
    border: 1px solid #ccc;

    &:disabled {
        .react-select>div:first-child {
            background: #e9ecef;
        }
    }
}

fieldset legend {
    font-size: 14px;
    width: auto;
    padding: 0 8px;
    margin: 0;
}

.disabled {
    cursor: not-allowed !important;
}

.alert-warning {
    color: #856404 !important;
    background-color: #fff3cd !important;
    border-color: #ffeeba !important;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;

    .border-danger {
        border-color: #f5c6cb !important;
    }
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
}

.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

//Show red disc for required fields.
//Slightly classier than asterisk.
label.required::before {
    content: '\f111';
    color: red;
    font-size: 0.4em;
    @include baseDotNotation;
    margin-right: 6px;
}

input:invalid~.invalid-feedback {
    display: none;
}

input:valid~.invalid-feedback {
    display: none;
}

.was-validated {
    input:invalid~.invalid-feedback {
        display: block !important;
    }

    input:valid~.invalid-feedback {
        display: none !important;
    }
}

.hidden-input-validation {
    height: 0;
    width: 0;
    visibility: hidden;
    padding: 0;
    margin: 0;
    float: right;
}

//Replace the fug bootstrap validation glyphs with FA
.was-validated .form-control:valid,
.form-control.is-valid {
    background-image: none !important;
    border-color: #ced4da !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: none !important;
}

[hidden] {
    display: none !important;
}

.bottom-underline {
    border-image: linear-gradient(270deg,
            rgba(204, 204, 204, 0.3),
            rgba(119, 119, 119, 1)) 1 !important;
    border-width: 0 0 2px 0 !important;
    background: none !important;
    border-style: solid !important;
    border-color: #ccc;

    &.danger {
        border-image: linear-gradient(270deg,
                rgba(204, 204, 204, 0.3),
                rgba(119, 119, 119, 1)) 1 !important;
        border-color: red;
    }
}

.box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.5) !important;
}

.popover {
    box-shadow: -4px 5px 11px -1px rgba(0, 0, 0, 0.5);

    .popover-header {
        padding: 0.25rem 0.75rem;

        &.danger {
            background: #f8b1b1;

            .fa {
                color: red;
            }
        }

        &.warning {
            background: #f5b26a;

            .fa {
                color: #895421;
            }
        }

        &.success {
            background: #9cd6f7;

            .fa {
                color: var(--success);
            }
        }
    }
}

//Overflow controllers
.overflow-y-auto {
    overflow-y: auto !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.flex-row-nowrap-left {
    display: flex !important;
    flex-flow: row nowrap !important;
    justify-content: flex-start !important;
    align-items: center !important;
}

//The below is stamped after form validation is called.
//Controls the display of validation styles.
.was-validated {
    .react-select.required.is-invalid>div:first-child {
        border-color: var(--danger);
    }
}

//hidden input text validation used for conditionals
input.hidden-validation {
    height: 0 !important;
    width: 0 !important;
    opacity: 0 !important;
    border: none !important;
}

//Smaller focused Form that opens to the right
.slide-form {
    position: fixed;
    right: 0;
    margin-right: -9999px;
    border: 1px solid #777;
    padding: 0;
    background: #fff;
    z-index: 1;
    height: calc(100% - 40px);
    box-shadow: -2px 8px 10px 1px #aaaaaa;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;

    header {
        padding: 0.5rem 0.5rem 0 0.5rem;
    }

    &.show {
        margin-right: 0;
    }

    >form:first-child {
        padding: 0;
        display: flex !important;
        flex-flow: column nowrap !important;
        flex: 1 !important;
        min-height: 0 !important;
    }

    fieldset {
        opacity: 1;
    }

    &.left {
        box-shadow: 2px 8px 10px 1px #aaaaaa;
        margin-right: unset;
        margin-left: -9999px;
        right: unset;
        left: 0;

        &.show {
            margin-left: 0;
        }
    }

    .slide-form-position {
        position: absolute !important;
        top: 0px !important;
        right: 0px !important;
    }
}

.font-size-100 {
    font-size: 100%;
}

//Form level close buttons
.site-form-close {
    position: absolute;
    top: 6px;
    right: 8px;
}

.fifty-px {
    width: 50px;
}

//Simple yes no badges in grids, standardizes width
.badge-yes-no {
    width: 40px;
    font-size: 0.7rem;
}

/* Progress Bar Primary Color */
.progress-bar {
    background-color: var(--defaultHighlight);
    /* Main bar color */
    animation: reactstrap-indeterminate 2s linear infinite;
    position: relative;
}

/* Progress Track (Supporting Background) */
.progress {
    background-color: var(--defaultHighlightAccent);
    /* Track color */
    height: 4px;
}

/* Default Spinner Color */
.spinner-border {
    color: var(--defaultHighlight);
    /* Default color */
}

.user-stacked-icon {
    font-size: 1.25em !important;
}

.page-subheading {
    .h6 {
        border-bottom: 2px solid var(--defaultHighlight);
    }
}

//Groups form-controls together in a bound box with common layout styles.
.grouped-row {
    padding-top: 10px;
    border-radius: 4px;
    border: 1px solid transparent;
    background-color: #fff;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;

    table {
        background: #fff;
    }
}

//Overlay for slide forms
.overlay {
    width: 0%;
    height: 100%;
    z-index: 30;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(34, 34, 34, 0.6);
    transition: opacity 0.2s linear, visibility 0.1s, width 1s ease-in;

    &.show {
        transition: opacity 0.5s ease, width 0s;
        opacity: 1;
        width: 100%;
        visibility: visible;
    }
}

//Toasts
.Toastify__toast {
    border-radius: 4px;
    box-shadow: 0px 6px 25px -10px rgba(0, 0, 0, 0.75);

    &.Toastify__toast--error {
        background: var(--danger) !important;
    }

    &.Toastify__toast--success {
        background: var(--success) !important;
    }

    &.Toastify__toast--warning {
        background: var(--warning) !important;
    }
}

.opacity-0 {
    opacity: 0 !important;
}

.opacity-half {
    opacity: 0.5 !important;
}

.opacity-1 {
    opacity: 1 !important;
}

.border-red-top-bottom {
    border-top: 1px solid var(--defaultHighlight);
    border-bottom: 1px solid var(--defaultHighlight);
}

.dashboard-link {
    .card-body {
        width: 180px;
        height: 140px !important;
        padding: 10px !important;

        @include phoneDown {
            width: 100%;
        }
    }

    &:hover {
        .card {
            border: 1px solid var(--defaultHighlight);
        }
    }
}

.dashboard-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 15px;

    @include phoneDown {
        .dashboard-link {
            width: 50%;
        }
    }
}

.simple-table-container {
    table {
        td {
            font-size: 0.76rem;
            border-left: 1px solid #dee2e6;
            border-right: 1px solid #dee2e6;
        }

        border-bottom: 1px solid #dee2e6;
    }

    th {
        border-left: 1px solid #dee2e6;
        border-right: 1px solid #dee2e6;
    }
}

table.scrollable-table {
    tbody {
        display: block;
        overflow-y: scroll;
    }

    thead,
    tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    thead {
        width: calc(100% - 1.2em);
    }

    td {
        font-size: 0.76rem;
    }
}

form#chargeTypeSelector {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 0 !important;

    .ag-header-cell-text {
        font-size: 12px;
    }

    .ag-theme-alpine .ag-row {
        font-size: 85% !important;
    }

    >header {
        margin-bottom: 0 !important;
    }

    .exception-badge {
        background-color: coral;
        font-size: 85%;
        text-transform: uppercase;
    }
}

table .invalid-feedback {
    font-size: 100% !important;
}

.reorder-list-widget-droppable {
    padding-top: 10px;
    padding-bottom: 10px;
}

.page-form {
    background: #fff !important;
    background-color: #fff !important;

    &>.column {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 46px);

        &>:not(.flex-fill-container) {
            flex: none;
        }

        &>.flex-fill-container {
            flex: 1;
            overflow: auto;
        }
    }
}

//Split.js resize gutter styles
.gutter.gutter-horizontal {
    cursor: ew-resize;
}

.text-overflow-ellipsis {
    text-overflow: ellipsis;
}

.site-form-disabled {
    opacity: 0.6;
    pointer-events: none !important;

    >* {
        pointer-events: none !important;
    }
}

.time-input-entry {
    cursor: pointer;
    border: 1px solid #ccc;
    min-width: 60px;
    max-width: 200px;
    padding: 2px;
    margin: 0;
    text-align-last: right;
    appearance: none;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 12px;
    text-align: center;

    option {
        cursor: pointer;
    }

    &:hover,
    &:focus {
        box-shadow: none !important;
    }
}

/* TimePicker panel default positioning */
.rc-time-picker-panel {
    position: absolute;
    /* Relative to the input field */
    z-index: 1000;
    width: auto !important;

    .rc-time-picker-panel-inner {
        width: 100%;
    }

    .rc-time-picker-panel-combobox {
        display: flex;
        flex-wrap: nowrap;
    }
}

/* Columns for hours, minutes, and AM/PM */
.rc-time-picker-panel-select {
    display: inline-block;
    width: 70px !important;
    /* Equal space for hours, minutes, and AM/PM */
    text-align: center;
    vertical-align: top;

    li {
        font-size: 14px;
    }
}

.time-picker-header {
    position: absolute;
    top: 0;
    /* Position above the input wrap */
    right: 0;
    /* Align to the right */
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* Footer containing the buttons */
.time-picker-footer {
    margin-top: 10px;
    /* Gap above the footer */
    width: 100%;
    display: flex;
    justify-content: center;
    /* Align buttons to the right */
    padding: 10px 0;
    border-top: 1px solid;
    border-color: $default_border;
}

.rc-time-picker-lg {
    .rc-time-picker-input {
        @extend .form-control;
    }
}

section.file-upload-section {
    border: 4px dashed #ccc;
    padding: 16px;

    .file-upload-section-inner {
        cursor: pointer;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }
}

.file-uploads-preview {
    display: flex;
    flex-flow: column wrap;
    margin-bottom: 10px;

    a {
        text-decoration: none;
        padding: 4px 8px;
    }
}

.file-upload-error {
    background: #f7c8c8;
    color: #bd0000;

    .fa-times {
        color: #bd0000;
        opacity: 0.8;
    }
}

.file-upload-error-name {
    max-width: 250px;
    text-overflow: ellipsis;
    font-size: 0.85em;
    overflow: hidden;
    white-space: nowrap;
}

.file-upload-toolbar {
    flex: 1;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}

.file-upload-progress {
    margin-top: 2px;
    border-radius: 4px;

    &.MuiLinearProgress-colorPrimary {
        background-color: #fff !important;
    }

    .MuiLinearProgress-barColorPrimary {
        background-color: #ccc;
        opacity: 0.8;
    }
}

.border-radius {
    border-radius: 4px;
}

.formblocker {
    background-color: rgba(255, 255, 255, 0.5) !important;
    transition: opacity 0.15s linear;
    display: none;
    z-index: 1;
    height: 100%;
    width: 100%;
    position: absolute !important;
    top: 0;
    left: 0;

    &.show {
        display: block;
        width: 100%;
    }

    &.overlay {
        position: absolute !important;
    }

    .formblocker-inner-progress {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }

    &.no-progress {
        .formblocker-inner-progress {
            display: none !important;
        }
    }
}

//alert utility classes, due to BS4 HSLA errors parsing var

.site-alert {
    position: relative;
    padding: 0.25rem 0.5rem !important;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;

    &.site-alert-primary {
        color: #004085;
        background-color: #cce5ff;
        border-color: #b8daff;
    }

    &.site-alert-secondary {
        color: #383d41;
        background-color: #e2e3e5;
        border-color: #d6d8db;
    }

    &.site-alert-success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

    &.site-alert-danger {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }

    &.site-alert-warning {
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
    }

    &.site-alert-info {
        color: #0c5460;
        background-color: #d1ecf1;
        border-color: #bee5eb;
    }

    &.site-alert-light {
        color: #818182;
        background-color: #fefefe;
        border-color: #fdfdfe;
    }

    &.site-alert-dark {
        color: #1b1e21;
        background-color: #d6d8d9;
        border-color: #c6c8ca;
    }
}

.rejection-note-header {
    border-bottom: 1px solid #f5c6cb;
}

.emergency {
    color: red;
    font-weight: bold;
}

//reusable job details component
.job-confirm-details,
.invoice-details {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    background: whitesmoke;
    margin-bottom: 15px;

    *:not(svg):not(.badge):not(.adj-indicator) {
        font-size: 12px;
        line-height: 12px;
        height: auto;
        padding: 1px 0;
    }

    >div {
        margin-bottom: 4px;
        padding-bottom: 4px;
        align-items: start !important;

        &:not(:last-child) {
            border-bottom: 1px solid rgba(204, 204, 204, 0.6) !important;
        }

        >input {
            width: auto !important;
            padding: 2px;
            height: 20px;

            &#customerName {
                width: 100% !important;
            }

            &#workOrderNumber {
                width: 400px !important;
            }

            &#glString {
                width: 400px !important;
            }

            &#memo {
                width: 400px !important;
            }

            &#jobLocation {
                width: 400px !important;
            }

            &#invoiceJob {
                width: 400px !important;
            }

            &#invoiceDate {
                padding-top: 5px;
            }
        }

        .react-select {
            width: 400px !important;
            padding-top: 0;
        }

        .react-select>div>div {
            padding-left: 0px;
        }

        .form-control-plaintext {
            width: 100% !important;
        }

        label {
            margin-top: 4px;
        }

        .form-control-plaintext {
            margin-top: 4px;
        }
    }

    label {
        width: 80px;
        margin-bottom: 0 !important;
    }

    .timesheet-status-label {
        //width: auto;
        font-weight: bold;
        text-transform: uppercase;
        border: none !important;
        outline: none !important;
        margin: 0;
        padding: 0;
        margin-left: 16px;
        font-size: 14px;
        color: #777;

        &.rejected,
        &.cancelpending,
        &.billing-payrollrejected,
        &.client-cancel {
            color: var(--danger);
        }

        &.approved,
        &.payrollapproved,
        &.billingapproved,
        &.billingrejected {
            color: var(--success);
        }
    }

    @media (min-width: 768px) {

        #workOrderNumber,
        #glString,
        #jobLocation {
            min-width: 380px;
        }
    }

    //Chrome renders disabled fields too light.
    input,
    input[disabled='disabled'] {
        -webkit-text-fill-color: #777 !important;
    }
}

.invoice-details {
    .control-label {
        width: 105px;
    }
}

.adj-indicator {
    margin-left: 20px;
    padding-top: 4px !important;
}

.adj-badge {
    padding: 2px 6px !important;
    margin-right: 8px;
    font-size: 100% !important;
}

#contractJobTable {
    .ct-job-number {
        width: auto !important;
    }

    .ct-job-name {
        width: auto !important;
    }

    .ct-job-active,
    .ct-job-week {
        width: 80px !important;
    }
}

.jobview-timesheets-completed {
    &::before {
        font-family: $font-fa;
        font-weight: 900;
        content: '\f017';
        margin-left: 12px;
    }
}

/*.jobview-crewlead {
    &::before {
        font-family: $font-fa;
        font-weight: 900;
        content: "\f807";
        margin-right: 8px;
        color: #ff8d00;
    }
}*/

.dashboard-stacked-icon {
    &::before {
        z-index: 2;
        background: #fff;
        padding: 0;
        margin: 0;
        border-radius: 2px;
        color: var(--defaultHighlight);
        @include bottomRightOuterBoxShadow;
    }

    &.rounded {
        &::before {
            border-radius: 12px;
        }
    }
}

.subcontractor-form-group {
    .react-select__multi-value {
        width: 100% !important;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }
}

.grid-status-bar {
    font-size: 0.85em;

    @include phoneDown {
        justify-content: center;
    }
}

.gridLoadingOverlay {
    border: 1px solid #ccc !important;
    border-radius: 6px;
    padding: 10px;
    @include bottomRightOuterBoxShadow;
    background: #fff;
}

.badge-email {
    background: #fff;
    color: #337ab7;
    border: 1px solid #337ab7;
}

.contract-email-label {
    width: 100%;
    border-bottom: 2px solid #ced4da;
}

.text-small {
    font-size: 0.85em !important;
}

.signature-modal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10001;
    height: 100% !important;
    width: 100% !important;
    background: rgba(255, 255, 255, 0.4);

    .signature-modal-dialog {
        padding: 10px;
        background: #ffffff;
        height: 100%;
        width: 100%;
        max-width: 568px;
        max-height: 320px;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid #777;
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.5);
    }

    .signatureWrapper {
        height: calc(100% - 26px) !important;
        position: relative;
        padding-bottom: 10px;
    }

    .cSignature {
        height: calc(100% - 26px);
        width: 100%;
    }

    .signature-modal-toolbar {
        flex: 0 0 !important;
    }
}

.page-heading-row {
    h5 {
        display: block;
    }
}

.full-screen-modal {
    .modal-title {
        width: 100%;
    }

    .time-input-entry {
        font-size: 1rem;
        margin-left: auto;
        line-height: 1rem;
    }

    .control-label {
        min-width: 90px;
    }

    .invalid-feedback {
        text-align: right;
    }
}

.chip-close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.chip-close-btn:hover {
    background-color: #6c757d;
    /* Bootstrap secondary color */
    color: white;
}

@include phoneDown {
    .menu-spacer {
        display: none;
    }

    .user-stacked-icon {
        display: none;
    }

    .navbar-brand {
        >img {
            max-height: 22px;
        }

        .brand-label {
            max-width: 180px;
        }
    }

    .site-collapser {
        padding-left: 0 !important;
    }

    .page-heading-row {
        h5 {
            margin: 6px 0 !important;
            padding-bottom: 4px !important;
            font-size: 1rem;
            text-align: center;
        }
    }

    .full-screen-modal {
        margin: 0;
        height: 100%;
        min-height: 0;

        .modal-content {
            height: 100%;
            border-radius: 0;
            display: flex;
            flex-flow: column nowrap;

            .modal-header {
                padding: 8px 16px;
            }

            .modal-title {
                display: block;
            }

            .modal-body {
                flex: 1;
                overflow-y: auto;
            }
        }
    }
}

/* Keyframes (keep all directives at page bottom) */
@-moz-keyframes jelly {
    from {
        transform: scale(1, 1);
    }

    30% {
        transform: scale(1.25, 0.75);
    }

    40% {
        transform: scale(0.75, 1.25);
    }

    50% {
        transform: scale(1.15, 0.85);
    }

    65% {
        transform: scale(0.95, 1.05);
    }

    75% {
        transform: scale(1.05, 0.95);
    }

    to {
        transform: scale(1, 1);
    }
}

@-webkit-keyframes jelly {
    from {
        transform: scale(1, 1);
    }

    30% {
        transform: scale(1.25, 0.75);
    }

    40% {
        transform: scale(0.75, 1.25);
    }

    50% {
        transform: scale(1.15, 0.85);
    }

    65% {
        transform: scale(0.95, 1.05);
    }

    75% {
        transform: scale(1.05, 0.95);
    }

    to {
        transform: scale(1, 1);
    }
}

@-o-keyframes jelly {
    from {
        transform: scale(1, 1);
    }

    30% {
        transform: scale(1.25, 0.75);
    }

    40% {
        transform: scale(0.75, 1.25);
    }

    50% {
        transform: scale(1.15, 0.85);
    }

    65% {
        transform: scale(0.95, 1.05);
    }

    75% {
        transform: scale(1.05, 0.95);
    }

    to {
        transform: scale(1, 1);
    }
}

@keyframes jelly {
    from {
        transform: scale(1, 1);
    }

    30% {
        transform: scale(1.25, 0.75);
    }

    40% {
        transform: scale(0.75, 1.25);
    }

    50% {
        transform: scale(1.15, 0.85);
    }

    65% {
        transform: scale(0.95, 1.05);
    }

    75% {
        transform: scale(1.05, 0.95);
    }

    to {
        transform: scale(1, 1);
    }
}

@keyframes fade {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

/* Keyframes */
@keyframes reactstrap-indeterminate {
    0% {
        left: -100%;
        /* Start off-screen */
        width: 50%;
        /* Start with a small width */
    }

    50% {
        left: 25%;
        /* Bar moves into the center */
        width: 50%;
        /* Bar expands */
    }

    100% {
        left: 100%;
        /* End off-screen */
        width: 50%;
        /* Bar shrinks again */
    }
}