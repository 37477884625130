#workflowStatuses {
    .badge {
        font-size: 1em !important;
        margin: 0.25em;
    }
    .card-group {
        margin-top:1em;
    }
    .form-control{
        height: inherit;
    }
    .nav-link{
        min-height: 3.5em;
    }
}
