#dailyRosterViewPage {
    .roster-left {
        display: flex;
        flex-flow: column nowrap;
        flex: 2;
    }

    .roster-right {
        margin-left:10px;
        margin-top: 30px;
        margin-bottom: 20px;
        flex-flow: column nowrap;
        flex: 1;
        display: none;
        border: solid 1px;
        border-color: var(--ag-border-color, #babfc7);

        &.show {
            display: flex;
        }
    }

    .ag-row {
        display: flex !important;
        flex-flow: row nowrap;
        align-items: center;
        overflow: visible !important;
        margin: 0 !important;
        padding: 0 !important;

        > span {
            flex: 1;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }
    }

    .daily-roster-row {
        padding: 0 10px;
        flex: 1;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .daily-roster-row-header {
        }

        .daily-roster-row-dispatch {
            width: 170px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
        }

        .daily-roster-row-dispatch-badge {
            width: 150px;
        }

        .daily-roster-row-employee {
        }
    }

    .badge-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
    }

    .badge {
        white-space: nowrap;
        text-align: center;
        padding: 4px 8px !important;
        font-size: 1em !important;
    }

    .view-header-text {
        font-weight: 700;
        color: #181d1f;
    }

    .muted {
        span {
            opacity: .6;
        }
    }
}
