﻿@import "./_mixins.scss";

//bootstrap default overrides and custom classes
$thick-grey-border: 8px solid rgba(0, 0, 0, 0.6);

$default_border: #bdbcbf;
$border_light: #dee2e6;
$drop_background: #8dbdf1;

$shadow-bottom: 0 4px 2px -3px gray;
$shadow-no-top: 0 8px 10px 3px gray;

$font-size-base: .85rem;

$navbar-height: 40px;
$navbar-brand-w: 125px;

$menu-background: #333;
$menu-color: #fff;
$menu-hover-color: #ccc;
$transition-collapse: height .05s ease !default;

$theme-colors: ( 
    'primary' : var(--primary), 
    'danger' : var(--danger), 
    'secondary' : var(--secondary), 
    'information' : var(--information), 
    'warning' : var(--warning), 
    'success': var(--success)
);