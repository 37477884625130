@import "../../style/_variables.scss";

//Note: these styles are used by timesheets.

.confirmationContainer {
    background-color: #fff;
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    min-height: 0;
    overflow-y: auto;
}

h4 {
    font-size: 16px;
    border-bottom: 4px solid #ccc;
    margin-bottom: 8px;
    cursor: pointer;
    display:flex;
    flex-flow:row nowrap;
    align-items:center;
    justify-content:space-between;
    padding-bottom:4px;
}

.locationDetails {
    padding: 4px 0;

    *:not(svg):not(.badge):not(.adj-indicator) {
        font-size: 14px;
        line-height: 14px;
        height: auto;
        padding: 1px 0;
    }

    label {
        width: 120px;
        font-size: 14px;
        margin-bottom:0;
    }

    .form-group {
        margin-bottom:6px;
    }
}

.crewAndEquipmentDetails {
    padding: 4px 0px;

    label {
        font-size: 14px;
        margin-bottom: 0;
    }

    .form-group {
        margin-bottom: 6px;
    }
}

.crewTimesheetDetails {
    border-style: solid;
    border-color: #b8daff;
    border-width: 1px 1px 1px 8px;
    margin-bottom: 10px;
    padding: 0 4px 0 4px;

    table {
        margin-bottom: 0;

        thead {
            th {
                border-bottom: none;
            }
        }
    }

    .not-applicable {
        width: 40px;
        text-align: center;

        .icon-check {
            color: #ccc;

            &.checked {
                color: var(--danger);
            }
        }
    }

    .start-time, .end-time {
        width: 60px;
        text-align: center;
    }
}

label.bottom-border {
    border-bottom: 1px solid #ccc;
    width:100%;
    margin-bottom:4px;
}

.assignmentsTable, .assignmentsTable {
    width: 100%;
}

.assignmentsTable {
    .iconCell {
        width:40px;
        text-align:center;
    }
    td {
        padding:4px;
        vertical-align:middle;
    }
}

#officeCallButton {
    background: #28a745;
    color:#fff;
    margin-right:8px;
}

.icon-check {
    color: #ccc;
    &.checked {
        color: #28a745
    }
}

a {
    cursor: pointer;
}

.userActions {
    border-top:1px solid #ccc;
    padding-top:8px;
}

.timesheetHeader {
    font-weight:bold;
    font-size:12px;
}

.invalid {
    input {
        border: 1px solid var(--danger);
    }
}

input.invalid {
    border: 1px solid var(--danger);
}

//Slim down webkit HTML5 time picker since this screen struggles for real estate.
//Ignore BS4 validation icons as well.  Not enough room.
input[type=time] {
    padding: 6px !important;
    background-image: none !important;    
}

div#validationMessages {
    display:none;
    flex-flow: row nowrap;
    align-items: center;
    justify-content:center;
}

form.was-validated:invalid {
    div#validationMessages {
        display: flex !important;
    }
}

#addChargeDropdown {
    border:none;
}

#timesheetsTable {
    th, td {
        &.time, &.client, &.crew {
            text-align:center;
        }
    }
}

.assignmentRow {
    &.owned {
        color: #004085 !important;
        background-color: #cce5ff;
        td {
            border-color: #b8daff !important;
        }
    }

    &.clickable {
        cursor: pointer;
    }
}

.fa-hard-hat-golden {
    color: goldenrod !important;
}

.crewLeadLabel {
    font-size:10px;
}