@import "../../style/_variables.scss";

.confirmationContainer {
    background-color: #fff;
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    min-height: 0;
    overflow-y: auto;
}

h4 {
    font-size: 16px;
    border-bottom: 4px solid #ccc;
    margin-bottom: 8px;
    cursor: pointer;
    display:flex;
    flex-flow:row nowrap;
    align-items:center;
    justify-content:space-between;
    padding-bottom:4px;
}

.locationDetails {
    padding: 4px 0;

    *:not(svg):not(.badge):not(.adj-indicator) {
        font-size: 14px;
        line-height: 14px;
        height: auto;
        padding: 1px 0;
    }

    label {
        width: 120px;
        font-size: 14px;
        margin-bottom:0;
    }

    .form-group {
        margin-bottom:6px;
    }
}

.crewAndEquipmentDetails {
    padding: 4px 0px;

    label {
        font-size: 14px;
        margin-bottom: 0;
    }

    .form-group {
        margin-bottom: 6px;
    }
}

.crewTimesheetDetails {
    .not-applicable {
        width: 40px;
        text-align: center;

        .icon-check {
            color: #ccc;

            &.checked {
                color: var(--danger);
            }
        }
    }

    .start-time, .end-time {
        width: 80px;
        text-align: center;
    }
}

label.bottom-border {
    border-bottom: 1px solid #ccc;
    width:100%;
    margin-bottom:4px;
}

.assignmentsTable, .assignmentsTable {
    width: 100%;
}

.assignmentsTable {
    .iconCell {
        width:40px;
        text-align:center;
    }
    td {
        padding:4px;
        vertical-align:middle;
    }
}

#officeCallButton {
    background: #28a745;
    color:#fff;
    margin-right:8px;
}

.icon-check {
    color: #ccc;
    &.checked {
        color: #28a745
    }
}

.workOrderGroup {
    max-width:240px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px 8px;
    background: whitesmoke;
    margin-bottom: 4px;

    label {
        min-width: 80px;
        font-size: 12px;
        margin-bottom: 0;
    }
}

a {
    cursor: pointer;
}

.userActions {
    border-top:1px solid #ccc;
    padding-top:8px;
}

.timesheetHeader {
    font-weight:bold;
    font-size:12px;
}

.invalid {
    input {
        border: 1px solid var(--danger);
    }
}

input.invalid {
    border: 1px solid var(--danger);
}

#employeeReview, #timesheetReview {
    .form-group {
        margin-bottom: 15px;
    }
}