#billingRateForm .bg-light {
    border-color: #ced4da;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
}

table.countyGroupTimesheetTable {
    tr td > *, tr th > * {
        vertical-align: middle;
    }

    .badge-column {
        width: 40px;
    }

    .not-applicable {
        width: 80px;
        text-align: center;
        vertical-align: middle;

        & label {
            margin-bottom: 0;

            & > .icon-check {
                color: #ccc;
        
                &.checked {
                    color: var(--danger);
                }
            }
        }
    }
    
    .numeric-input {
        width: 100px;
        text-align: left;
        vertical-align: middle;
    }
}