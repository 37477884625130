@import "../../style/_variables.scss";
@import 'react-big-calendar/lib/sass/variables'; 

//RLC: Override any calendar//day styles here using vars.
$out-of-range-color: lighten(#333, 40%);
$out-of-range-bg-color: lighten(#333, 70%);

$calendar-border: #777;
$cell-border: #777;

$border-color: #777;

$segment-width: percentage(1 / 7);

$time-selection-color: white;
$time-selection-bg-color: rgba(0,0,0, .50);
$date-selection-bg-color: red;// rgba(0,0,0, .10);


$event-bg: #777;
$event-border: darken(#3174ad, 10%);
$event-outline: #3b99fc;
$event-color: #fff;
$event-border-radius: 5px;
$event-padding: 2px 5px;
$event-zindex: 4;

$btn-color: #373a3c;
$btn-bg: #fff;
$btn-border: #ccc;

$current-time-color: #74ad31;

$rbc-css-prefix: rbc-i;

$today-highlight-bg: #c0e1fa;

@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';

//RLC: Any other flourishes here.  Scope by page name class so as not to affect other site styles.
.assignmentCalendarPage {
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    overflow-y: hidden;
    z-index: 1;
    position: relative;

    .rbc-event {
        min-height: 40px;
        font-size: .85em;
        display: flex;
        align-items: stretch;
        position: relative;
    }

    .rbc-event.week,
    .rbc-event.day {
        position: absolute;
    }

    .rbc-event-content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .rbc-event-content a {
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: inherit;
    }

    .confirmed {
        background: #afa;
        color: green;
        border: 1px solid green;
    }

    .unconfirmed, .rejected {
        background: pink;
        color: maroon;
        border: 1px solid maroon;
    }

    .unconfirmed, .confirmed, .rejected {
        cursor: auto;

        & a:hover {
            text-decoration: none;
        }
    }

    .rejected {
        padding-left: 18px !important;
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        &::before {
            color: maroon;
            position: absolute;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            content: '\f057';
            left: .25em;
            top: 50%;
            margin-top: -.5em;
            height: 1em;
            width: 1em;
            line-height: 1;
        }

        &.small {
        }
    }

    .confirm-legend {
        margin-top: 8px;

        small {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            border-radius: 4px;
            padding: 2px 10px;

            &:first-child {
                margin-right: 8px;
            }
        }
    }

    .rbc-calendar {
        height: calc(100% - 34px); //leave room for the legend
    }

    .rbc-event-label {
        display: none;
    }

    .rbc-event-content {
        display: flex;
    }

    .rbc-date-cell > a {
        font-size: 14px;
    }
}

div.calendar-content {
    background-color: #fff;
}

@include phone {
    #eventsCalendar {
        height: 100%;

        > .container-fluid:first-child {
            padding-top: 8px;
            padding-left: 4px;
            padding-right: 4px;
        }
    }

    h5 {
        display: none;
    }

    .rbc-event.month {
        .event-job-title {
            display: none;
        }
    }
}