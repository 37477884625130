// ManageTenantTheme.scss

.form-group-flex {
    display: flex;
    margin-bottom: 1rem;

    .color-input-container {
        display: flex;
        width: 70%;
    }

    .color-preview {
        width: 15%;
        height: 100%;
    }
}

.ml-2 {
    margin-left: 0.5rem;
}
