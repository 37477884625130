@import "../../../style/_variables.scss";

.form-group {
    display: flex;
    flex-flow:column nowrap;
}


//Show red disc for required fields.
//Slightly classier than asterisk.
input[required] + label::before {
    content:'\f111';
    color: red;
    font-size: .4em;
    @include baseDotNotation;
    margin-right: 6px;
}