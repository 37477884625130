@import "../../style/_variables.scss";

.form-group {
    display: flex;
    flex-flow:column nowrap;
}

.emp-info-left, .emp-info-right {
    //RLC: the below allows us to blanket apply red dot notation 
    //by selecting any inputs that are required, then adding the 
    //required styles.
    .form-group {
        flex-flow:column nowrap;
    }
}